@import "sass/helpers/variables";
@import "sass/helpers/fonts";
@import "sass/helpers/mixins";
@import "sass/helpers/extend";







* {
  @include prefixer(box-sizing, border-box, webkit moz o ms);
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


html {
  overflow: none;
  padding: 0;
  margin: 0;
  font-size: 90%;
}



body {
  background: #fff;
  direction: var(--direction);
  text-align: #{var(--start-direction)};
  color: var(--firstColor);
  // background: #f0f5fc;
  line-height: 1.6;
  height: 100%;
  touch-action: manipulation;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  font: {
    family: var(--mainfontfamilyEn);
    size: var(--mainSizeFont);
  }
}

input::placeholder {
  font-family: var(--mainfontfamilyEn);
}

::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.page-wrapper {
  position: relative;
  max-width: var(--wrapperWidth);
}

.container {
  max-width: 100%;
}

a,
button {
  text-decoration: none;
  cursor: pointer;
  @include prefixer(transition, 300ms, webkit moz o ms);
  color: #000000;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
  color: var(--firstColor);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  margin: 0;
  color: var(--firstColor);
  font-family: var(--mainfontfamilyboldEn);
  font-weight: bold !important;
}

strong,
b,
.font-bold {
  font-weight: bold;
  font-family: var(--mainfontfamilyboldEn);
}

p {
  color: var(--thirdColor);
  position: relative;
  text-transform: none;
}

span {
  color: var(--thirdColor);
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;
}

input,
button,
select,
textarea {
  @include prefixer(appearance, none, webkit moz o ms);
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.paddingsections {
  padding: var(--paddingSectionsPlus);
}


img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

button:focus {
  outline: 0;
}

.secondary-color {
  color: var(--secondColor);
}

/*shared*/

.owl-theme {
  .owl-dots {
    margin-top: -24px !important;
    position: relative !important;
    z-index: 1 !important;
  }
}

.slide_sec {
  img {
    height: 250px;
    width: 100%;
  }
}

.categories-labels-sec {
  position: relative;
  border-bottom: 1px solid var(--fourthColor);

  .categories-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    li {
      padding-inline-end: 0.5rem;
      flex-grow: 1;

      button,
      a {
        width: 100%;
        min-width: 85px;
        text-align: center;
        // padding: 0.6rem 0.6rem;
        color: #707070;
        border-color: #707070;
        background-color: transparent;
        height: 100%;
        border-radius: 6px;
        justify-content: center;

        &.active-cat,
        &:hover,
        &:focus {
          color: var(--secondColor) !important;
          border-color: var(--secondColor) !important;
          background-color: transparent !important; 
          font-weight: bold !important;
          svg{
            path{
              fill : var(--secondColor) !important;
            }
         
          }
        }
        svg{
          padding-right: 5px !important;
          width: 20px !important;
        }
      }
    }

    li:last-child {
      padding-inline-end: 0rem;
    }

  }
}

.search-sec {
  .form-group {
    .input-group-abs {
      position: relative;
      >div {
        position: absolute;
        padding: 0.8rem 0.8rem;
        z-index: 1;
        line-height: 1;
        top: 0;
        bottom: 0;
       
        left:  0 !important ;
        right: unset !important;
      }

      .form-control {
        padding-left: 2.35rem;
        border-radius: 0.5rem !important;
        height: calc(2em + 0.75rem + 2px);
        background-color: var(--fifthColor);
      }
    }
  }
}


.categories-sec {
  ul {
    gap: 0.5rem;

    li {
      button {
        background-color: transparent;

        &:hover,
        &:focus {
          color: var(--secondColor)
        }
      }
    }
  }

  .cards-sec {
    .col-card {
      margin-bottom: 1rem;


      .card {
        border: 0;
        text-align: center;
        display: flex;
        flex-direction: column-reverse;
        height: auto !important;
        box-shadow: none;

        // >a {
        //   display: block;
        //   border-radius: 1rem;
        //   overflow: hidden;
        //   margin-bottom: 1rem;
        //   transition: none;

        .card-img-top {
          height: 115px;
          max-height: 115px;
          width: 115px;
          max-width: 115px;
          border-radius: 1rem;
        }

        // }

        .card-body {
          padding: 0;

          h6 {
            margin-bottom: 0;
          }

          p {
            display: none;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }

        .addcard {
          display: none;
          align-items: center;
          justify-content: space-between;

          h6 {
            font-size: 12px;
          }

          .line-through {
            text-decoration: line-through;
            color: var(--danger);
            padding: 0 0.3rem;
          }

          button {
            display: flex;
            align-items: center;
            color: var(--thirdColor);
            border-color: var(--thirdColor);
            background-color: transparent;
            padding: 2px 15px !important;
          }
        }
      }

    }



    &.list-mode {
      .col-card {
        flex: 100%;
        max-width: 100%;
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid var(--fourthColor);

        .card {
          flex-direction: row;
          text-align: start;

          .card-body {
            padding-inline-end: 1rem;
            display: flex;
            flex-direction: column;
            padding-top: .5rem;
            padding-bottom: .5rem;
            // flex-grow: 1;
            // -ms-flex: 0 0 58.333333%;
            // flex: 0 0 58.333333%;
            // max-width: 58.333333%;

            p {
              display: -webkit-box;
            }

            .addcard {
              margin-top: auto;
              display: flex;
            }
          }

        }

      }
    }
  }
}








// .slide_sec.home-slide .slide-header-postion-relative {
//   position: relative;
//   color: #000;
//   box-shadow: rgb(0, 0, 0, 0.1) 0px 2px 15px;
//   -webkit-box-shadow: -rgb(0, 0, 0, 0.1) 0px 2px 15px;
//   -moz-box-shadow: -rgb(0, 0, 0, 0.1) 0px 2px 15px;

// }

.bg-gray {
  background-color: #F5F5F5 !important;
}

.border-bottom-none {
  border-bottom: none !important;
}

.color-black {
  color: #000 !important;
}

.active-color {
  color: var(--secondColor);
}

.service-button {
  border-radius: 0.5rem;

  svg {
    width: 25px;

    path {
      fill: gray;
    }
  }
}

.service-button:hover {
  svg {
    width: 25px;

    path {
      fill: var(--secondColor);
    }
  }
}

.active-cat {
  svg {
    path {
      font-weight: bold;
      fill: var(--secondColor);
    }
  }
}

.search-location:focus {
  outline: none !important;
  border: none !important;
}

.form-control:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}


.menu-card {
  background-color: var(--fifthColor);
}

// .search-sec .form-group .input-group-abs>div {
//   // top : 50px !important;
//   z-index: 1;
// }



.search-sec .form-group .input-group-abs .form-control:focus{
  z-index: unset !important;
}






.border-bottom-1 {
  border-bottom: 1px solid #F0F0F0 !important;

}

.page.page-inner .page-container .page-btn {
  a {
    background-color: var(--secondColor) !important;
    color: #fff;
  }
}

.category-menu-items-title {
  font-size: 1.2rem !important;
  font-weight: 600;
}

.date-time-class::-webkit-scrollbar {
  width: 0.25px !important;
  height: 1px;
}


.date-time-class::-webkit-scrollbar-thumb {
  background-color: var(--secondColor) !important;
}

.date-time-class>div {
  cursor: pointer;
}

.page.page-inner .page-container .page-btn .btn-date-time {
  border-radius: 10px !important;
  background: #fff !important;
  color: #000 !important;
  border: 1px solid var(--secondColor) !important;
  cursor: pointer !important;
  bottom: 0 !important;
}

.page.page-inner .page-container .page-btn .btn-date-time:hover,
.page.page-inner .page-container .page-btn a:hover {
  color: #000 !important;
  background-color: var(--secondColor) !important;
}

.radio:checked+label:before,
.checkbox:checked+label:before {
  border: 5px solid var(--secondColor) !important;
  cursor: pointer;
}

.item-title {
  font-size: 18px;
  margin-bottom: 0.5rem;
}

.item-desc {
  font-size: 13px;
  margin-bottom: 0.5rem;
}

.item-title-2 {
  font-size: 14px;
  // margin-bottom: 1rem;
}

.note-path {
  fill: var(--secondColor);
}

.bg-gray-2 {
  background-color: #F5F5F5 !important;
}

.font-14 {
  font-size: 14px !important;
}

.color-black {
  color: #000 !important;
}

.page-btn-checkout {
  border: 1px solid var(--secondColor) !important;
  cursor: pointer !important;
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 8px;

}

.page-btn-checkout:hover {
  color: #fff !important;
  background-color: var(--secondColor) !important;
}






[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #000;
}

[type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 100%;
  background: #fff;
}

[type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #000;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-radio-item {

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    left: auto;
    right: 0 !important;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    left: auto;
    right: 4px !important;
  }

  [type=radio]:checked+label,
  [type=radio]:not(:checked)+label {
    padding-right: 28px;
    padding-left: unset !important;
  }

}


.promocode-confirm {
  font-size: 12px;
  position: absolute;
  height: 100%;
  width: 50px;
  top: 0;
  line-height: 50px;
  z-index: 4;
  text-align: center;
  font-weight: 700;
  right: 0 !important;
  left: unset !important;

}



.disableForm {
  pointer-events: none !important;
}

.last-category {
  border-bottom: none !important;
}

.input-border-bottom {
  border-color: #F0F0F0 !important;
}



.showMenuTop {
  top: 0 !important;
  transition: all 0.2s !important;
}

img {
  object-fit: cover;
}



/*--arabic--*/


.order-detail-btn {
  text-align: center;
  padding: 20px 0 10px;
}

.order-detail-btn a {
  color: #65ac70 !important;
  font-weight: 600;
}

.tab-label a {
  color: green !important;
  font-weight: 600;
}

.order-status-time-date {
  padding: 0 10px;
}

.td-align-right {
  text-align: right;
  font-weight: 600;
}

.med-logo {
  /* border:1px solid black; */
  border-radius: 10%;
  ;
  min-width: 65px;
  max-width: 65px;
  max-height: 100px;
  width: 65px;
}

.truck-info-min h3 {
  font-size: 1rem;
  font-weight: 600;
}


.truck-notes {
  white-space: pre-line;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  height: 2.6em;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.med-logo img {
  width: 60px;
  height: 60px;
  object-fit: contain;
}


.left-margin {
  margin-left: -15px;
}

.tracking-line {
  border-left: 3px dashed #495057;
  margin: 30px auto;
  display: table;
}



.page-content {
  padding-bottom: 60px;
}

.page-content {
  min-height: calc(100vh - 200px);
}

.page-container {
  padding-bottom: 25px;
}

/* line 108, src/sass/layout/_layout.scss */
.page-container .page-btn {

  /* position: -webkit-fixed; */

}

.product-img img {
  object-fit: contain;
}

.page-heading {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.checkout-btn {
  flex: 4;
}


.checkout-btn a.btn.btn-primary {
  text-align: left !important;
}

.im.im-arrleft {
  -webkit-transform: initial;
  transform: initial;
}

app-footer div {
  background: white;
  height: 20px;
  text-align: center;
}

/***** Menu page *****/
a {
  cursor: pointer;
}

.loader-container {
  margin: auto auto;
  text-align: center;
  /* width:64px;
  height:64px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -32px;
  margin-left: -32px;
  z-index: 1000; */
}

/*Loader*/
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #dfa937;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 01.1, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}


.footer-copyright {
  position: fixed;
  bottom: 0;
  height: 40px;
  width: 620px;
  background-color: white;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 1px;
}

.page-btn {
  width: 620px;
}

@media screen and (max-width: 991px) {
  .footer-copyright {
    width: 100%;

  }

  .page-btn {
    width: 100%;
  }
}

.page.page-inner .page-side .page-side-inner {
  background-image: url('/assets/images/side-bg.jpg');
}

.page.page-inner .page-side .page-side-inner:after {
  background: rgba(247, 238, 249, 0.2);
}

agm-map img {
  max-height: initial;
}



.text-right {
  text-align: left !important;
}




.owl-dialog-container {
  overflow: visible !important;
}

.swal2-container {
  .swal2-popup {
    border-radius: 10px !important;
    width: 23em !important;
    padding: 35px 13px !important;
  }

  .swal2-title {
    color: #000;
    font-size: 18px !important;
  }

  .swal2-content {
    padding: 7px 20px !important;
    color: #000;
    font-size: 14px !important;
    line-height: 1.8 !important;
  }

  .swal2-actions {
    margin-top: 5px !important;
  }

  button {
    border-radius: 0.64em !important;
    color: #000 !important;
    padding: 4px 29px !important;
    font-size: 12px !important;
    background-color: #fff !important;
    flex-grow: 1 !important;
  }

  button:hover {
    background-image: unset !important;
    color: #fff !important;
  }

  button:focus {
    box-shadow: unset !important;
  }

  .swal2-styled.swal2-cancel {
    border: 1px solid rgb(220, 97, 108) !important;
  }

  .swal2-styled.swal2-confirm {
    border: 1px solid rgb(106, 162, 119) !important;
  }

  .swal2-styled.swal2-cancel:hover {
    background: rgb(220, 97, 108) !important;
  }

  .swal2-styled.swal2-confirm:hover {
    background: rgb(106, 162, 119) !important;
  }

}


// .swal2-title {
//   color: #fff !important;
// }

// .swal2-content {
//   color: #fff !important;
// }



/* fix cat title */
.cat-title h3 {
  margin-top: 1.2rem !important;
}

.page-heading {
  display: none !important;
}

.color-white {
  color: white !important;
}



.btn-center {
  background-color: #EFBB28 !important;
  display: block !important;
  margin: auto !important;
  border-color: #EFBB28 !important;
}

.nsm-content .menu-alert p {
  color: #3B3B3B !important;
}

.nsm-content {
  background-color: white !important;
  border-radius: 10px !important;
}

.notice {
  color: #3B3B3B !important;
}

.menu-alert h1 {
  border-bottom: unset !important;
}




.pointer {
  cursor: pointer
}



.value-item {

  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 1px solid #dcdcdc;
  margin: auto;
  display: inline-block;
  text-align: center;
  line-height: 1.8;
  cursor: pointer;
  margin-inline-start: 5px;
}

.value-item:first-child {
  margin-inline-start: 0px;
}

.quantity {
  width: 30px;
  height: 32px;
  line-height: 2.1;
  border-radius: 5px;
}


.gallary_modal {

  .modal-content {
    height: 400px;
    max-width: 400px;
    width: 100%;
    background: #fff;
    margin: auto;
    margin-top: 20%;
  }

  .owl-item {
    // height: 500px;
  }

  // .owl-stage,
  .modal-body {
    padding: 0px
  }

  img {
    height: 400px !important;
    width: auto !important;
    object-fit: contain;
    margin: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // border: 3px solid #dbe4ed; 
  }

  .icon-close {
    position: absolute;
    z-index: 2;
    margin: 10px;
    width: 25px;
    height: 25px;
    background:rgb(34 34 34 / 33%);
    color: #fff;
    text-align: center;
    line-height: 25px;
    border-radius: 20px;
    padding: 0px;
    font-size: 14px;
  }
}

.container-padding-5{
  padding-left: 5px !important;
  padding-right: 5px !important;
}

// media screen 
@media screen and (max-width: 650px) {
  .search {
    width: 100%
  }
}

@media screen and (max-width: 500px) {
  .checkout-page .radio-label-st {
    font-size: 0.85rem !important;
    font-weight: 500 !important;
  }

  .nsm-content {
    margin: 0.5rem;
  }

  .font-size-st .order-placed {
    font-size: 0.9rem !important;
  }

  .order-success .nsm-dialog {
    top: 10% !important;
  }

  .order-success {
    overflow-y: hidden;
  }

  .respons-col {
    padding: 0 !important;
    margin: 0;
  }

  .order-details {
    font-size: 0.8rem;
  }
}



@media screen and (max-width: 350px) {
  .response-text-center {
    display: table;
    margin: 0 auto;
  }

  .response-text-center td {
    padding: 0 20px;
  }

  .tab-label::after {
    margin-left: -7px;
  }

  .lang-ar .tab-label::after {
    margin-left: 0;
    margin-right: -7px;
  }
}

@media screen and (max-width: 320px) {

  .order-success {
    overflow-y: hidden;
  }

  .order-success .nsm-dialog {
    top: 1% !important;
  }

  .font-size-st .order-placed {
    font-size: 0.8rem !important;
  }
}



/* iphone 3 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 1) {
  .cat-title h3 {
    font-size: 1rem !important;
  }
}

/* iphone 4 */
@media only screen and (min-device-width: 320px) and (max-device-height: 480px) and (-webkit-device-pixel-ratio: 2) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (-webkit-device-pixel-ratio: 2) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone 6, 6s, 7, 8 */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone X , XS, 11 Pro */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone XR, 11 */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 2) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}

/* iphone XS Max, 11 Pro Max */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (-webkit-device-pixel-ratio : 3) {
  .cat-title h3 {
    font-size: 1.1rem !important;
  }
}


@media (min-width: 320px) {
  .cat-main-pic img {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .cat-main-pic img {
    width: 70% !important;
  }
}

.back {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-color: #fff;
  font-size: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: center !important;
  border-radius: 50%;
  z-index: 2;
  padding-top: 5px;
  padding-inline-end: 3px;
}

.font-weight-normal{
  font-weight: normal !important;
}



.page.page-inner{
  // overflow-x: hidden !important;
}

.btn-bg-seconday {
  background-color: var(--secondColor) !important;
  color: #fff !important;
}
.btn-bg-seconday :hover {
  background-color: var( white) !important;
  color: #000 !important;
  border: 1px solid  var(--secondColor) !important; ;
}

button.btn.btn-primary {
  background-color: var(--secondColor) !important;
  border: 0 !important;
  color: #fff;
  display: block;
  padding: 10px 20px;
  font-weight: 500;
  text-align: center;
}



@keyframes switch-colors {
  0% {fill : #8E8E8E;}
  10% {fill : #8E8E8E;}
  20% {fill : #8E8E8E;}
  30% {fill : #8E8E8E;}
  40% {fill : #8E8E8E;}
  50% {fill : #8E8E8E;}
  60%{fill:var(--secondColor)}
  70%{fill:var(--secondColor)}
  80%{fill:var(--secondColor)}
  90%{fill:var(--secondColor)}
  100%{fill:var(--secondColor)}
}

.switch-color {
  -webkit-animation:switch-colors 2s infinite;
  animation:switch-colors 2s infinite;
}


// .search-sec .form-group .input-group-abs > div{
//   right: 0px;
// }





////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////
////////////////////////////////////////// arabic   //////////////////////////////////////


.lang-ar {
  text-align: right;
  direction: rtl;

  font : {
    family: var(--mainfontfamilyAr), sans-serif;
  }

  // letter-spacing: -0.1px;

  .value-item {
    span {
      line-height: 1.9;
      text-align: center;
    }
  }

  .quantity {
    line-height: 2.4;
  }

}

.lang-ar * {



  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  strong,
  b,
  .font-bold {
    font-family: var(--mainfontfamilyboldAr), sans-serif;
    font-weight: bolder !important;
  }

  input::placeholder {
    font-family: var(--mainfontfamilyAr);
  }
}



.lang-ar {
  .back {
    left: unset;
    right: 10px;
  }
}

/* line 44, src/sass/ar/_base.scss */
.lang-ar.page.page-inner .page-container .page-heading .heading-l .title {
  font-weight: 600;
}

/* line 48, src/sass/ar/_base.scss */
.lang-ar .menu-item .info {
  padding: 0 0 0 50px;
}



/* line 55, src/sass/ar/_base.scss */
.lang-ar.page.page-inner .page-side .lang-switcher {
  right: auto;
  left: 10px;
}

/* line 61, src/sass/ar/_base.scss */
.lang-ar .menu-item .price-block {
  margin: 0 auto 0 0;
}



/* line 68, src/sass/ar/_base.scss */
.lang-ar .menu-item .price-block .price {
  text-align: left;
}

/* line 72, src/sass/ar/_base.scss */
.lang-ar .menu-item .price-block .num {
  direction: ltr;
}

/* line 76, src/sass/ar/_base.scss */
.lang-ar.page.page-inner .page-container .page-heading .heading-l .title a.back {
  margin: 0 0 0 5px;
}

/* line 80, src/sass/ar/_base.scss */
.lang-ar .product-block .product-info .info-heading .title {
  font-weight: 900;
}

/* line 84, src/sass/ar/_base.scss */
.lang-ar .checkbox+label:before {
  margin: 0 0 0 10px;
}

/* line 88, src/sass/ar/_base.scss */
.lang-ar .checkbox:checked+label::after {
  left: auto;
  right: 3.6px;
  top: 4.5px;
}

/* line 93, src/sass/ar/_base.scss */
.lang-ar .product-block .product-options .product-options-list .item .price {
  padding: 0 30px 0 0;
}

/* line 97, src/sass/ar/_base.scss */
.lang-ar .product-block .product-quantity .num {
  direction: ltr;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

/* line 103, src/sass/ar/_base.scss */
.lang-ar .input-wrapper i.im {
  left: auto;
  right: 2px;
}

/* line 107, src/sass/ar/_base.scss
.lang-ar .input-wrapper input[type="text"], .lang-ar .input-wrapper select  {
  padding: 10px 40px 10px 10px;
} */

/* line 111, src/sass/ar/_base.scss */
.lang-ar .textarea-wrapper i.im {
  left: auto;
  right: 10px;
}

/* line 115, src/sass/ar/_base.scss */
.lang-ar .textarea-wrapper textarea {
  padding: 10px;
}

/* line 119, src/sass/ar/_base.scss */
.lang-ar .page-search .page-search-content .search-body .search-cat .search-cat-body .item a {
  padding: 10px 40px 10px 10px;
}

/* line 123, src/sass/ar/_base.scss */
i.im.im-deladdress,
i.im.im-pay,
i.im.im-arrleft {
  display: inline-block;
  /* Gecko */
  /* Opera */
  -webkit-transform: scaleX(-1);
  /* Webkit */
  transform: scaleX(-1);
  /* Standard */
  -webkit-filter: FlipH;
  filter: FlipH;
  /* IE 6/7/8 */
}

/* line 135, src/sass/ar/_base.scss */
.lang-ar .form-group .form-group-heading i.im {
  margin: 0 0 0 20px;
}

/* line 139, src/sass/ar/_base.scss */
.lang-ar .radio-group .item {
  margin: 0 0 0 40px;
}

/* 
/* line 143, src/sass/ar/_base.scss */
.lang-ar .radio+label:before {
  margin-right: 0;
  margin-left: 5px;
}

/* line 146, src/sass/ar/_base.scss */
.lang-ar .radio+label:after {
  left: initial;
  right: 0;
}

/* line 151, src/sass/ar/_base.scss */
.lang-ar .product-block .product-info .info-heading .title {
  padding: 0 0 0 20px;
}

/* line 155, src/sass/ar/_base.scss */
.lang-ar .menu-block .menu-area .delivery-info .item {
  padding: 0 0 20px 20px;
}


/* line 162, src/sass/ar/_base.scss */
.lang-ar .menu-block .menu-area .delivery-info .item .val .now i.im {
  margin: 0 0 0 5px;
}

.pull-right {
  float: left;
}

.pull-left {
  float: right;
}

.lang-ar .mask-container {
  transform: scale(-1, 1);
}

.lang-ar .cat-title h6 {
  padding-left: 0;
  padding-right: 5px;
  text-align: right;
}

.lang-ar .selected-dial-code {
  direction: ltr;
}

.lang-ar .phone-input {
  direction: ltr;
  text-align: left;
}

.lang-ar .basket-cart-item-count {
  width: 42px;
}

.lang-ar .cart-item-count {
  right: -18px;
}


.lang-ar .respect-dir {
  direction: rtl;
}

.lang-ar .fa-angle-right:before {
  content: "\f104" !important;
}

.lang-ar .fa-angle-left:before {
  content: "\f105" !important;
}

.im-ayin:before {
  font-size: 1.2rem !important;
  font-family: sans-serif;
  content: "AR";
}

.lang-ar .im-ayin:before {
  font-size: 1.2rem !important;
  font-family: sans-serif;
  content: "EN";
}

.lang-ar .nsm-dialog-btn-close {
  right: initial;
  left: 8px;
}

.lang-ar .left-margin {
  margin-left: 0;
  margin-right: -15px;
}

.lang-ar .tracking-line {
  border-right: 3px dashed #495057;
  border-left: none;
}

.lang-ar .td-align-right {
  text-align: left;
}

.lang-ar {
  .promocode-confirm {

    left: 0 !important;
    right: unset !important;
  }



  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked+label,
  [type="radio"]:not(:checked)+label {
    position: relative;
    padding-right: 28px;
    cursor: pointer;
    line-height: 1;
    display: inline-block;
    color: #000;
  }

  [type="radio"]:checked+label:before,
  [type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #000;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked+label:after,
  [type="radio"]:not(:checked)+label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #000;
    position: absolute;
    top: 4px;
    right: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }





  .custom-radio-item {

    [type="radio"]:checked+label:before,
    [type="radio"]:not(:checked)+label:before {
      right: auto !important;
      left: 0 !important;
    }

    [type="radio"]:checked+label:after,
    [type="radio"]:not(:checked)+label:after {
      right: auto !important;
      left: 4px !important;
    }

    [type=radio]:checked+label,
    [type=radio]:not(:checked)+label {
      padding-left: 28px !important;
      padding-right: unset !important;
    }
  }
  .search-sec {
    .form-group {
      .input-group-abs {
        >div {
          right:  0 !important ;
          left: unset !important;
        }}}}

}

.lang-ar .show-btn {
  right: auto !important;
  left: 10px !important;
}

.lang-ar .p-r-65 {
  padding-left: 57px !important;
  padding-right: 10px !important;
}

.lang-ar #sidebar-wrapper {
  margin-right: -220px;
  margin-left: 0;
}

.lang-ar #wrapper.toggled {
  padding-right: 220px;
  padding-left: 0;
}

.lang-ar .sidebar-nav li:before {
  left: auto !important;
  right: 0 !important;
}

.lang-ar .text-left {
  text-align: right !important;
}

.lang-ar .menu-alert h1 {
  font-size: 2em !important;
}



.lang-ar .m-l-20 {
  margin: 0 20px 0 0 !important;
}


.lang-ar .m-r-20 {
  margin: 0 0 0 20px !important;
}

.lang-ar .margin-to-time {
  margin: 0 !important;
}

.lang-ar .cart-item {
  padding-left: 0 !important;
  /* padding-right:12px !important; */
}

.lang-ar .cart-total {
  padding-left: 0 !important;
  /* padding-right:12px !important; */
}

.lang-ar .ar-right {
  float: right;
  text-align: right;
}

.lang-ar .delivery-area {
  padding-bottom: 30px !important;
}

.lang-ar .text-right {
  text-align: right !important;
}

.lang-ar .card-column {
  float: right !important;
}

.lang-ar .minus-icon {
  border-radius: 0 0 5px 0;
}

.lang-ar .plus-icon {
  border-radius: 0 0 0 5px;
}

.lang-ar .pull-left {
  float: right !important;
}


.lang-ar .checkout-btn a.btn.btn-primary {
  text-align: right !important;
}

.lang-ar .im.im-arrleft {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.lang-ar .reversed-phone {
  display: inline-block;
  direction: ltr;
  text-align: right
}
.lang-ar{
  
  .categories-labels-sec {
  
    .categories-labels {
     
  
      li {
       
  
        button,
        a {
         
          font-size: 12px  !important;
          svg{
            padding-left: 5px !important;
            width: 25px !important;
          }
        }
      }
  
      
  
    }
  }
  
}
.page-wrapper{
  max-width: 100%;
  width: 100%;
}

@media screen and (max-width: 575px) {
  .lang-ar .menu-txt {
    margin-right: 10px;
    margin-left: 0 !important;
  }

  .lang-ar .menu-block .menu-area .delivery-info .item {
    padding: 0 0 20px 15px;
  }

  /* line 48, src/sass/ar/_base.scss */
  .lang-ar .menu-item .info {
    padding: 0 0 0 30px;
  }
}

@media screen and (max-width: 767px) {

  /* line 61, src/sass/ar/_base.scss */
  .lang-ar .menu-item .price-block {
    padding: 0 0 0 10px;
  }
}

@media screen and (max-width: 991px) {
  .page.page-inner .page-side.sm-hidden {
    display: none
  }
}
@media screen and (max-width: 337px) {


  
  .categories-labels-sec {
  
    .categories-labels {
     
  
      li {
       
  
        button,
        a {
          font-size: 10px  !important;
          svg{
            padding-left: 3px !important;
            width: 20px !important;
          }
        }
      }
  
      
  
    }
  }
  

}

@media screen and (max-width: 420px) {
  .font-sm-size{
    font-size: 10px !important;
  }

  
  .lang-ar{
  
    .categories-labels-sec {
    
      .categories-labels {
       
    
        li {
         
    
          button,
          a {
            font-size: 9px  !important;
            svg{
              padding-left: 3px !important;
              width: 23px !important;
            }
          }
        }
    
      }
    }
    
  }
}

@media screen and (max-width: 350px) {

  
  .lang-ar{
  
    .categories-labels-sec {
    
      .categories-labels {
       
    
        li {
         
    
          button,
          a {
            font-size: 8px  !important;
            svg{
              padding-left: 3px !important;
              width: 20px !important;
            }
          }
        }
    
        
    
      }
    }
    
  }
}


@media screen and (max-width: 301px) {

  
  .lang-ar{
  
    .categories-labels-sec {
    
      .categories-labels {
       
    
        li {
         
    
          button,
          a {
            font-size: 6px  !important;
            svg{
              padding-left: 2px !important;
              width: 18px !important;
            }
          }
        }
    
        
    
      }
    }
    
  }
}


@media only screen and (max-width: 425px) {
  .ex-inner{
    height: unset !important;
    .ex-img{
      min-width: 40px !important;
    min-height: 40px !important;
    width: 40px !important;
    height: 40px !important;
    }
  }

//     .lang-ar .custom-radio-item [type=radio]:checked + label, .lang-ar .custom-radio-item [type=radio]:not(:checked) + label {
//       padding-left: 22px !important;
//       padding-right: unset !important;
//   }
  
//   .lang-en .custom-radio-item [type=radio]:checked + label, .lang-en .custom-radio-item [type=radio]:not(:checked) + label {
//     padding-right: 22px !important;
//     padding-left: unset !important;
// }

.custom-radio-item {
  [type=radio]:checked+label,
  [type=radio]:not(:checked)+label {
    padding-right: 22px !important;
    padding-left: unset !important;
  }
  [type=radio]:checked+label,
  [type=radio]:not(:checked)+label {
    padding-right: 28px !important;
    padding-left: unset !important;
  }
}

.lang-ar{
  .custom-radio-item {
    [type=radio]:checked+label,
  [type=radio]:not(:checked)+label {
    padding-left: 28px !important;
    padding-right: unset !important;
  }
  [type=radio]:checked+label,
  [type=radio]:not(:checked)+label {
    padding-left: 22px !important;
    padding-right: unset !important;
  }
  }
 
}

  .value-item{

    width: 22px !important;
    height: 22px !important;
    border-radius: 50%;
    border: 1px solid #dcdcdc;
    margin: auto;
    display: inline-block;
    text-align: center;
    line-height: unset !important;
    cursor: pointer;
    -webkit-margin-start: 5px !important;
    margin-inline-start: 5px !important;
    span {
     line-height: unset !important ;
  }
  }
  .quantity {
   
    border-radius: 4px !important;
}
  .px-3{
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .font-small{
    font-size: 10px !important;
  }
}

////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////
////////////////////////////////////////// END arabic   //////////////////////////////////////